/*!
 * ReformSlider
 * Version 0.1
 * Part of the 80 Days Reform Pattern Library
 * http://www.eighty-days.com
 * 
 *
 * TODO:
 * - Implement pagination
 * - Trigger callback functions
 * - Fix the default options
 *
 */
 

(function($){
    $.reformSlider = function (el, options) {
        // To avoid scope issues, use 'base' instead of 'this'
        // to reference this class from internal events and functions.
        var base = this;
        
        // Access to jQuery and DOM versions of element
        base.$el = $(el);
        base.el = el;
        
        // Add a reverse reference to the DOM object
        base.$el.data('accordiom', base);
        
        base.init = function () {
            base.options = $.extend({}, $.reformSlider.defaultOptions, options);
            
            // Put your initialization code here
        };
        
        // Sample Function, Uncomment to use
        // base.functionName = function(paramaters){
        // 
        // };
        
        // Run initializer
        base.init();
    };
    
    $.reformSlider.defaultOptions = {
	    speed: 2000,
        animationSpeed: 500,
        beforeChange: function () {},
        afterchange: function () {},
        onLoad: function () {},
        arrows: false,
        pagination: false
    };
    
    $.fn.reformSlider = function (options) {
        return this.each( function () {
            (new $.reformSlider(this, options));
            
            // For some reason the default options weren't being extended so I'll do it manually
            if (!options) {
	            options = $.reformSlider.defaultOptions;
            }
            
            
            
            // Fire the onLoad callback once all's set up
            if (options.onLoad) {
	            options.onLoad.call(this, this);
            }
            
            
            
            // Set the init classes
            $(this).addClass('reform-slider-initialized');
            $(this).children().addClass('reform-slide').hide();
            $(this).children().first().addClass('reform-slide-current').show();
            
            var slideCount = $(this).children('.reform-slide').length;
            
            // Add the arrows?
            if ((slideCount > 1) && (options.arrows === true)) {
	            $(this).after('<ul class="reform-slider-arrows"><li><a href="#" class="reform-prev">Previous</a></li><li><a href="#" class="reform-next">Next</a></li></ul>');
	            $('.reform-prev').on('click', previousSlide);
	            $('.reform-next').on('click', nextSlide);
            }
            
            if (slideCount > 1) {
	            setInterval(nextSlide, options.speed);
            } else {
	            console.log('ReformSlider: 1 or fewer slides found');
            }
            
            //setTimeout(nextSlide, 2000);
            
            
            // Fade to the next slide
            function nextSlide() {
	            var $currentSlide = $('.reform-slide-current');
	            
	            if ( $currentSlide.next().length > 0 ) { // Check if there are more slides after this...
		            $currentSlide.next().show();
		            
		            $currentSlide.fadeOut(options.animationSpeed, function () {
			           $currentSlide.removeClass('reform-slide-current').hide();
			           $currentSlide.next().addClass('reform-slide-current');
		            });
				} else { // ... if not loop back to the first slide.
					$currentSlide.siblings().first().show();
					
					$currentSlide.fadeOut(options.animationSpeed, function () {
						$currentSlide.removeClass('reform-slide-current').hide();
			            $currentSlide.siblings().first().addClass('reform-slide-current');
					});
				}
            }
            
            
            // Fade to the previous slide
            function previousSlide() {
	            var $currentSlide = $('.reform-slide-current');
	            
	            if ( $currentSlide.prev().length > 0 ) { // Check if there are more slides before this...
		            $currentSlide.prev().show();
		            
		            $currentSlide.fadeOut(options.animationSpeed, function () {
			           $currentSlide.removeClass('reform-slide-current').hide();
			           $currentSlide.prev().addClass('reform-slide-current');
		            });
	            } else { // ... if not loop back to the last slide.
		            $currentSlide.siblings().last().show();
		            
					$currentSlide.fadeOut(options.animationSpeed, function () {
						$currentSlide.removeClass('reform-slide-current').hide();
			            $currentSlide.siblings().last().addClass('reform-slide-current');
					});
	            }
            }
            
            
        });
    };
    
    
    // Function: show all accordion items
    $.fn.reformSlider.openAll = function (el) {
        $(el).children('.accordionContent').slideDown($(el).data('accordiom-speed'));
    };
    
    // Function: hide all accordion items
    $.fn.reformSlider.closeAll = function (el) {
        $(el).children('.accordionContent').slideUp($(el).data('accordiom-speed'));
    };
    
    // Function: open item n (zero indexed)
    $.fn.reformSlider.openItem = function (el, n) {
        var nIndexCount = $(el).children('.accordionButton').length-1;
        if ((n < 0) || (n > nIndexCount)) { // Quick error check
            throw('Accordiom: No accordion item of index ' + n + ' exists');
        } else {
            $($(el).children('.accordionButton')[n]).trigger('click');
        }
    };
    

    
})(jQuery);