$(document).foundation();

$(document).ready( function () {

	$(function(){
		$('#dp1').fdatepicker({
			//initialDate: '02-12-1989',
			format: 'mm-dd-yyyy',
			disableDblClickSelection: true,
			leftArrow:'<<',
			rightArrow:'>>',
			//closeIcon:'X',
			closeButton: true
		});
	});

	$(function(){
		$('#dp2').fdatepicker({
			//initialDate: '02-12-1989',
			format: 'mm-dd-yyyy',
			disableDblClickSelection: true,
			leftArrow:'<<',
			rightArrow:'>>',
			//closeIcon:'X',
			closeButton: true
		});
	});

	$(function(){
		$('#dp3').fdatepicker({
			//initialDate: '02-12-1989',
			format: 'mm-dd-yyyy',
			disableDblClickSelection: true,
			leftArrow:'<<',
			rightArrow:'>>',
			//closeIcon:'X',
			closeButton: true
		});
	});



$('.timepicker').wickedpicker();
$('.hasWickedpicker').val('')

    scaleVideo();
    reform();
    checkPopupCookie();

     //$(".reveal-popup").trigger('click')

	var divWidth = $('.group-hotels-item  .outer-div').width() + 10;


    function checkPopupCookie() {
        var visited = $.cookie('visited');
        var delay = $('#newsletter-popup').data('popup-delay');

        if (visited !== 'yes') {
        // set cookie
            $.cookie('visited', 'yes');

            setTimeout(function () {
                $(".reveal-popup").trigger('click');
                $(window).trigger('resize');

            }, delay*1000);


        // end reveal code
        }
    };



	 $(".group-hotels-item  .outer-div").each(function(){
		//alert('text');
		$('.group-hotels-item  .outer-div').height(divWidth);
		$('.group-hotel-content-item').css('marginTop', divWidth);​
	});

$(window).on("resize",function(){
console.log("hello")
var divWidth = $('.group-hotels-item  .outer-div').width();
    $(".group-hotels-item  .outer-div").each(function(){
		//alert('text');
		$('.group-hotels-item  .outer-div').height(divWidth);
		$('.group-hotel-content-item').css('marginTop',  divWidth);​
	});

});




    $('.vertical-center').each( function () {
        $(this).wrapInner('<div class="vertical-center-table"><div class="vertical-center-row"><div class="vertical-center-cell"></div></div></div>');
    });

    $('.burger-primary a').on('click', function () {
        if ( $(this).attr('href').indexOf('#') > -1 ) {
            $('#offCanvas .close-button').trigger('click');
        }
    });

    $('.header-burger').on('click', function () {
        $('.app-download').fadeIn();
    });


    $('.app-download .close-app').on('click', function () {
        $('.app-download').fadeOut();
    });


    $(".video-pop").fancybox({
        type: 'iframe',
        wrapCSS: 'fancybox-video',
        width: '80%',
        height: '80%',
        scrolling: 'no',
        padding: [35, 14, 4, 14]
    });

    setHomeSliderHeight();
    $(window).on('resize', setHomeSliderHeight);
    /*$('.home-slider-inner').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        speed: 1000,
        arrows: false,
        fade: true
    });*/
    $('.home-slider-inner').reformSlider({
        arrows: true,
        speed: 6000,
        animationSpeed: 1000
    });

    // Disable zoom animation for IE
    if (detectIE() === false) {
        // Is not IE
    } else if (detectIE() <= 11) {
        $('body').addClass('browser-is-ie');
    }



    // Change the homepage header to transparent when above the image slider
    if ( $('.home-slider-inner').length > 0 ) {
        $(window).on('scroll', checkHomepageScroll);
        checkHomepageScroll();
    }


    if ( $('.detail-main video').length > 0 ) {
        $('.header').addClass('detail-video');
    }

    function checkHomepageScroll() {
        if ( $(window).scrollTop() >  ( $('.home-slider-inner').outerHeight() - 100 ) ) {
            $('.header').removeClass('home-header');
        } else {
            $('.header').addClass('home-header');
        }
    }



    // Change the homepage header to transparent when above the video
    if ( $('.video-desktop').length > 0 ) {
        $(window).on('scroll', checkHomepageVideoScroll);
        checkHomepageVideoScroll();
    }

    function checkHomepageVideoScroll() {
        if ( $(window).scrollTop() >  ( $('.video-desktop').outerHeight() - 100 ) ) {
            $('.header').removeClass('home-header');
        } else {
            $('.header').addClass('home-header');
        }
    }



    $(window).on("resize",function(){
		if ( $('.video-desktop').length > 0 ) {
		    if ( $(window).scrollTop() >  ( $('.video-desktop').outerHeight() - 100 ) ) {
	            $('.header').removeClass('home-header');
	        } else {
	            $('.header').addClass('home-header');
	        }
	    }
	});



    if ( $('.video-mobile').length < 0 ) {
        $('.header').addClass('video-mobile-header');
    }




    $('.home-highlights-carousel-inner').slick({
        slidesToShow: 3,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        speed: 1000,
        adaptiveHeight: true,
        arrows: true,
        prevArrow: '#home-highlights-carousel-prev',
        nextArrow: '#home-highlights-carousel-next',
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });



    $('.offers-carousel-inner').slick({
        slidesToShow: 1,
        autoplaySpeed: 7000,
        pauseOnHover: false,
        speed: 1000,
        arrows: true,
        adaptiveHeight: true,
        prevArrow: '#offers-carousel-prev',
        nextArrow: '#offers-carousel-next'
    });



    $('.gallery-slider-inner').slick({
        slidesToShow: 1,
        autoplaySpeed: 7000,
        pauseOnHover: false,
        speed: 1000,
        arrows: true,
        fade: true,
        prevArrow: '#gallery-slider-prev',
        nextArrow: '#gallery-slider-next'
    });



    if ( $('.landing-carousel-item').length >= 3 ) {

        $('.landing-carousel-inner').slick({
            slidesToShow: 3,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            speed: 1000,
            arrows: true,
            infinite: false,
            prevArrow: '#landing-carousel-prev',
            nextArrow: '#landing-carousel-next',
            responsive: [
                {
                    breakpoint: 640,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });

    } else {

        $('.landing-carousel-inner').addClass('no-carousel');
        $('#landing-carousel-prev, #landing-carousel-next').remove();

    }

    $('.rooms-slider-inner').reformSlider({
        arrows: true,
        speed: 4000,
        animationSpeed: 700
    });

    $('.rooms-content').perfectScrollbar();




    $('.group-hotel-buttons button').on('click', function () {
        var $toggleContent = $(this).parent().next();
        $toggleContent.toggleClass('open');
        if ( $toggleContent.hasClass('open') ) {
            $toggleContent.slideDown();
            $(this).text('Read Less');
        } else {
            $toggleContent.slideUp();
            $(this).text('Read More');
        }
    });






    $('.detail-slider-inner').slick({
        autoplay: true,
        fade: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        speed: 1000,
        arrows: false
    });




    $('.detail-form form').not('#previewForm').validate({
        debug: false
    });


    $('#file').on('change', function () {
        if ($(this)[0].files.length > 0) {
            $('.file-upload').text('Attached ' + $(this)[0].files[0].name);
        }
    });


    $('.accordion').accordiom({
        showFirstItem: false
    });


    $('.seasonal-slider').slick({
        autoplay: true,
        fade: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        speed: 1000,
        arrows: false
    });

    // $('input[type="text"].date').fdatepicker({

    // });

    var nowTemp = new Date();
    var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);
    
    var checkin = $('#check-in').fdatepicker({
        format: 'dd/mm/yyyy',
        onRender: function (date) {
            return date.valueOf() < now.valueOf() ? 'disabled' : '';
        }
    }).on('changeDate', function (ev) {
        if (ev.date.valueOf() > checkout.date.valueOf()) {
            var newDate = new Date(ev.date)
            newDate.setDate(newDate.getDate() + 1);
            checkout.update(newDate);
        }
        checkin.hide();
        $('#check-out')[0].focus();
    }).data('datepicker');
    
    var checkout = $('#check-out').fdatepicker({
        format: 'dd/mm/yyyy',
        onRender: function (date) {
            return date.valueOf() <= checkin.date.valueOf() ? 'disabled' : '';
        }
    }).on('changeDate', function (ev) {
        checkout.hide();
    }).data('datepicker');


    $('.area-of-interest-button span.accordion-arrow').on('click', function() {
        $(this).toggleClass('icon-arrow-circle-right');
        $(this).toggleClass('icon-arrow-circle-down');
    });

    //home video play pause

    $('#home-video-play-img, .iframe-wrap').click(function() {
        console.log('iframe clicked');
        var iframeMobile = $('iframe#video-mobile');
        var playerMobile = new Vimeo.Player(iframeMobile);

        playerMobile.getPaused().then(function(paused) {
            if ( paused == true ) {
                playerMobile.play();
                homeVideoPlay();
            } else {
                playerMobile.pause();
                homeVideoPause();
            }
        });

    });

		$('#home-video-play').click(function(){
			$('.video-mobile-placeholder').hide();
		});



    // $('#bgvidMobile').click( function(){
    //     var video = $("#bgvidMobile").get(0);

    //     if (video.paused) {
    //         video.play();
    //     } else {
    //         video.pause();
    //     }
    // });
    // $('#home-video-play').click( function(){
    //     $('#bgvidMobile').click();
    // });

    // $('#bgvidMobile').on('play', homeVideoPlay);
    // $('#bgvidMobile').on('pause', homeVideoPause);

    function homeVideoPlay() {
        $('#home-video-play-img').hide();
    }

    function homeVideoPause() {
        $('#home-video-play-img').show();
    }

    //home video volume button
    ////home - video - volume button - desktop

    $('.video-bar input[type="checkbox"]').on('change', function () {

        var iframeDesktop = $('iframe#desktop-video');
        var playerDesktop = new Vimeo.Player(iframeDesktop);

        if ($(".video-desktop .video-bar").hasClass("off")) {
			playerDesktop.setVolume(0.66);
			//alert('turn on');
            $('.video-desktop .video-bar').addClass('on');
            $('.video-desktop .video-bar').removeClass('off');
            $('.video-desktop #volumeOff').css('visibility', 'hidden');
            $('.video-desktop #volumeOn').css('visibility', 'visible');
            $(".video-desktop #volumeSwitch").checked = true;
		} else if ($(".video-desktop .video-bar").hasClass("on")) {
			playerDesktop.setVolume(0);
			//alert('turn off');
			$('.video-desktop .video-bar').addClass('off');
            $('.video-desktop .video-bar').removeClass('on');
			$('.video-desktop #volumeOff').css('visibility', 'visible');
            $('.video-desktop #volumeOn').css('visibility', 'hidden');
            $(".video-desktop volumeSwitch").checked = false;
		}



        var iframeMobile = $('iframe#video-mobile');
        var playerMobile = new Vimeo.Player(iframeMobile);


        if ($(".video-mobile .video-bar").hasClass("off")) {
			playerMobile.setVolume(0.66);
			//alert('turn on');
            $('.video-mobile .video-bar').addClass('on');
            $('.video-mobile .video-bar').removeClass('off');
            $('.video-mobile #volumeOffMobile').css('visibility', 'hidden');
            $('.video-mobile #volumeOnMobile').css('visibility', 'visible');
            $(".video-mobile #volumeSwitch").checked = true;
		} else if ($(".video-mobile .video-bar").hasClass("on")) {
			playerMobile.setVolume(0);
			//alert('turn off');
			$('.video-mobile .video-bar').addClass('off');
            $('.video-mobile .video-bar').removeClass('on');
			$('.video-mobile #volumeOffMobile').css('visibility', 'visible');
            $('.video-mobile #volumeOnMobile').css('visibility', 'hidden');
            $(".video-mobile #volumeSwitch").checked = false;
		}
    });
});


function scaleVideo() {
    var viewportWidth = $(window).innerWidth();
    var viewportHeight = $(window).innerHeight();

    var $videoElement;
    if ( $('.videoWrapper #desktop-video').length > 0 ) {
        $videoElement = $('.videoWrapper #desktop-video');
    } else if ( $('.full-page-video video').length > 0 ) {
        $videoElement = $('.full-page-video video');
    } else {
        return;
    }

    var videoWidth = $videoElement.attr('width');
    var videoHeight = $videoElement.attr('height');

    // Aspect ratio = height / width
    var viewportRatio = viewportHeight / viewportWidth;
    var videoRatio = videoHeight / videoWidth;

    //console.log('Viewport: ', viewportRatio, ' Video: ', videoRatio);


    var newWidth = (videoWidth / videoHeight) * viewportHeight
    var newHeight = (videoHeight / videoWidth) * viewportWidth;
    var newTop = 0;

    if (viewportRatio > videoRatio) {
        // set video height to the viewport, and extend the video beyond the viewport width, i.e. portrait viewport
        //console.log('crop left and right');
        $videoElement.css('height', viewportHeight);
        $videoElement.css('width', newWidth);
        $videoElement.css('left', '-' + (newWidth - viewportWidth) / 2 + 'px');
        $videoElement.css('top', '0');
    } else if (viewportRatio < videoRatio) {
        // set video width to the viewport, and extend the video beyond the viewport height, i.e. landscape viewport
        //console.log('crop top and bottom');
        $videoElement.css('width', viewportWidth);
        $videoElement.css('height', newHeight);
        $videoElement.css('left', '0');
        $videoElement.css('top', '-' + (newHeight - viewportHeight) / 2 + 'px');
    } else {
        // set the video width AND height to the viewport size, i.e. viewport is the same ratio as the video
        //console.log('no crop');
        $videoElement.css('height', viewportHeight);
        $videoElement.css('width', viewportWidth);
        $videoElement.css('left', '0');
        $videoElement.css('top', '0');
    }
}


function setHomeSliderHeight() {
    $('.home-slider, .home-slider-inner').height( window.innerHeight );
}


$(window).resize(function() {
    var width = $(document).width();
    if (width < 530) {
       $('.detail-slider video').trigger('pause');
    }
});

$(window).resize(function() {
    var width = $(document).width();
    if (width > 530) {
       $('.detail-slider video').trigger('play');
    }
});

/* $(window).on("orientationchange",function(){
	var width = $(document).width();
    if (width < 530) {
       $('.detail-slider video').trigger('pause');
    }
});

$(window).on("orientationchange",function(){
	var width = $(document).width();
    if (width < 530) {
       $('.detail-slider video').trigger('pause');
    }
}); */
